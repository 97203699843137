import { CommonHelper } from "@/models/common/common-helper";
import { LeadNeedSurveyCommentAnswerModel } from "../../answers/models/comment-answer-model";
import { LeadNeedSurveyQuestionBaseModel } from "../leadNeedSurveyQuestionBaseModel";
import { LeadNeedSurveyOneTextFieldAnswerModel } from "../../answers/models/one-text-field-answer-model";
import { LeadNeedSurveyTwoTextFieldAnswerModel } from "../../answers/models/two-text-field-answer-model";
import { LeadNeedSurveyUniqueDropdownAnswerModel } from "../../answers/models/unique-dropdown-answer-model";
import { LeadNeedSurveyDatePickerAnswerModel } from "../../answers/models/date-picker-answer-model";
import { LeadNeedSurveyNumericAnswerModel } from "../../answers/models/numeric-answer-model";
import { LeadNeedSurveyCityAndPostalCodeAnswerModel } from "../../answers/models/city-and-postal-code-answer-model";
import { LeadNeedSurveyAddressAnswerModel } from "../../answers/models/address-answer-model";
import { LeadNeedSurveyMultipleDropdownAnswerModel } from "../../answers/models/multiple-dropdown-answer-model";
import { LeadNeedSurveyAvailabilitiesAnswerModel } from "../../answers/models/availabilities-answer-model";
import { LeadNeedSurveyChildNumbersdAnswerModel } from "../../answers/models/child-numbers-answer-model";
import { LeadNeedSurveyAnswerBaseModel } from "../../answers/LeadNeedSurveyAnswerBaseModel";
import { ResultTypeEnum } from "../../enums/resultTypeEnum";
import { CreateElement, VNode } from "vue/types/umd";

export class LeadNeedSurveyQuestionComponentFactory {
    public Answers: LeadNeedSurveyAnswerBaseModel[];
    private _component: any;

    public constructor(component: any) {
        this.Answers = [];
        this._component = component;
    }

    public renderQuestion(type: ResultTypeEnum, question: LeadNeedSurveyQuestionBaseModel, h: CreateElement): VNode {
        const answerModel = this.createComponent(type);
   
        this.setQuestionAnswer(question.ID, answerModel.create(question.Answer));
   
        return answerModel.render(h, this.Answers[question.ID], question.Items, question.ID, question.Text, question.SubQuestions);
    }
   
    private setQuestionAnswer(questionID: number, answer: LeadNeedSurveyAnswerBaseModel) : void {
        if (CommonHelper.isNullOrUndefined(this.Answers[questionID])) {
            this.Answers[questionID] = answer;
        }
    }
   
    private createComponent(type: ResultTypeEnum): LeadNeedSurveyAnswerBaseModel {
        switch (type) {
            case ResultTypeEnum.Comment:
                return new LeadNeedSurveyCommentAnswerModel(null);
            case ResultTypeEnum.OneTextField:
                return new LeadNeedSurveyOneTextFieldAnswerModel(null);
            case ResultTypeEnum.TwoTextField:
                return new LeadNeedSurveyTwoTextFieldAnswerModel(null, null);
            case ResultTypeEnum.UniqueDropdown:
                return new LeadNeedSurveyUniqueDropdownAnswerModel(null);
            case ResultTypeEnum.DatePicker:
                return new LeadNeedSurveyDatePickerAnswerModel(null);
            case ResultTypeEnum.NumericField:
                return new LeadNeedSurveyNumericAnswerModel(null);
            case ResultTypeEnum.CityAndPostalCode:
                return new LeadNeedSurveyCityAndPostalCodeAnswerModel(null, null);
            case ResultTypeEnum.Address:
                return new LeadNeedSurveyAddressAnswerModel(null);
            case ResultTypeEnum.MultipleDropdown:
                return new LeadNeedSurveyMultipleDropdownAnswerModel(null);
            case ResultTypeEnum.Availabilities:
                return new LeadNeedSurveyAvailabilitiesAnswerModel(null, this._component);
            case ResultTypeEnum.ChildNumbers:
                return new LeadNeedSurveyChildNumbersdAnswerModel(null, this._component);
            default:
                break;
        }
    }
}