export class Address {
    public fullAddress: string;
    public streetNumber : string;
    public streetName : string;
    public postalCode : string;
    public city : string;

    public constructor(fullAddress : string, streetName : string, streetNumber: string) {
        this.fullAddress = fullAddress;
        this.streetName = streetName;
        this.streetNumber = streetNumber;
    }
}