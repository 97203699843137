import { LeadNeedSurveyContext } from "@/infrastructure/leadNeedSurvey/leasNeedSurvey.context";
import { SendLeadNeedSurveyModel } from "@/models/customer/leadNeedSurvey/commons/send-lead-need-suvey-model";
import { CreateLeadNeedSurveyCommandResult } from "@/models/customer/leadNeedSurvey/createLeadNeedSurveyResult";
import { LeadNeedSurveyConfigurationResponse } from "@/models/customer/leadNeedSurvey/leadNeedSurveyConfigurationResponse";

export class LeadNeedSurveyService {
    private _leadNeedSurveyContext: LeadNeedSurveyContext;

    public constructor(leadNeedSurveyContext: LeadNeedSurveyContext) {
        this._leadNeedSurveyContext = leadNeedSurveyContext;
    }

    public getLeadNeedSurveyConfiguration(surveyType: string): Promise<LeadNeedSurveyConfigurationResponse> {
        return this._leadNeedSurveyContext.getLeadNeedSurveyConfiguration(surveyType);
    }

    public createLeadNeedSurvey(body: SendLeadNeedSurveyModel): Promise<CreateLeadNeedSurveyCommandResult> {
        return this._leadNeedSurveyContext.createLeadNeedSurvey(body);
    }

    public updateLeadNeedSurvey(body: SendLeadNeedSurveyModel, leadNeedSurveyID: number): Promise<any> {
        return this._leadNeedSurveyContext.updateLeadNeedSurvey(body, leadNeedSurveyID);
    }
}