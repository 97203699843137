























import appFactories from "@/app.factories";
import { Address } from "@/models/address/Address";
import { AddressResponseModel } from "@/models/address/AddressResponseModel";
import { CommonHelper } from "@/models/common/common-helper";
import Vue from "vue";

export default Vue.extend({
    name: 'AddressSearch',
    props: {
        model: String,
    },

    data() {
        return {
            value: '' as string,
            defaultValue: null as Address,
            items: [] as Address[],
            minValueLength: 10 as number,
            valueLengthRemaining: 10 as number
        }
    },

    mounted() {
        this.init();
    },

    methods: {
        init() : void {
            if (CommonHelper.isNotNullOrUndefined(this.model)) {
                const address = new Address(this.model, this.model, '');
                this.items.push(address);
                this.defaultValue = address;
            }
        },

        onSearch() : void {
            if (this.value.length > this.minValueLength) {
                appFactories.services.address.searchAddress(this.value).then((addresses: AddressResponseModel[]) => {
                    this.items = addresses.map((addressModel: AddressResponseModel) => addressModel.address);
                });
            } else {
                this.valueLengthRemaining = this.minValueLength - this.value.length;
            }
        },

        onChange(data: Address) : void {
            this.$emit('change', data);

            this.items = [data];
            this.value = '';
            this.valueLengthRemaining = 10;
        }
    },

    computed: {
        getHintText() : string {
            let v = '' as string;

            if (this.value.length < this.minValueLength) {
                v = `${this.valueLengthRemaining} ${this.$t('Components.AddressSearch.charRemains')}`;
            }

            return v;
        }
    }
})
