import { CommonHelper } from "@/models/common/common-helper";
import { LeadNeedSurveyAnswerBaseModel } from "../LeadNeedSurveyAnswerBaseModel";
import { CreateElement, VNode } from "vue/types/umd";

export class LeadNeedSurveyNumericAnswerModel extends LeadNeedSurveyAnswerBaseModel {
    public NumericFieldValue: number;

    public constructor(numericFieldValue: number) {
        super();
        this.NumericFieldValue = numericFieldValue;
    }

    public create(value: string) : LeadNeedSurveyNumericAnswerModel {
        return CommonHelper.isNotNullOrUndefined(value) ? JSON.parse(value) as LeadNeedSurveyNumericAnswerModel : new LeadNeedSurveyNumericAnswerModel(null);
    }

    public render(h: CreateElement, customerAnswerModel: LeadNeedSurveyNumericAnswerModel) : VNode {
        return h('v-text-field', {
            class: 'form-control',
            attrs: { 
                type: 'tel',
                placeholder: '12345...',
                value: customerAnswerModel.NumericFieldValue
            },
            on: {
                input: (event: number) : void => {
                    customerAnswerModel.NumericFieldValue = event;
                }
            }
        });
    }
}