import IAddressContext from "@/core/common/iaddress.context";
import HttpRepository from "../http.repository";
import appConfig from "@/app.config";
import { AddressResponseModel } from "@/models/address/AddressResponseModel";

export class AddressContext extends HttpRepository implements IAddressContext {
    private webApiUrls = {
        getAddress: `${appConfig.webApiBaseUrl}/Geocoding/Positions`,
    };

    public searchAddress(address: string): Promise<AddressResponseModel[]> {
        return this.httpGet(`${this.webApiUrls.getAddress}?address=${address}`);
    }

}