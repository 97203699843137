import { CommonHelper } from "@/models/common/common-helper";
import { LeadNeedSurveyAnswerBaseModel } from "../LeadNeedSurveyAnswerBaseModel";
import InputDatePicker from "@/app/components/common/InputDatePicker.vue";
import { CreateElement, VNode } from "vue/types/umd";

export class LeadNeedSurveyDatePickerAnswerModel extends LeadNeedSurveyAnswerBaseModel {
    public DatePickerValue: Date;

    public constructor(datePickerValue: Date) {
        super();
        this.DatePickerValue = datePickerValue;
    }

    public create(value: string) : LeadNeedSurveyDatePickerAnswerModel {
        return CommonHelper.isNotNullOrUndefined(value) ? JSON.parse(value) as LeadNeedSurveyDatePickerAnswerModel : new LeadNeedSurveyDatePickerAnswerModel(null);
    }

    public render(h: CreateElement, customerAnswerModel: LeadNeedSurveyDatePickerAnswerModel) : VNode {
        return h(InputDatePicker, {
            attrs: {
                value: customerAnswerModel.DatePickerValue,
            },
            on: {
                input: (event: Date) : void => {
                    customerAnswerModel.DatePickerValue = event;
                }
            }
        });
    }
}