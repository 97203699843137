import { CommonHelper } from "@/models/common/common-helper";
import { LeadNeedSurveyAnswerBaseModel } from "../LeadNeedSurveyAnswerBaseModel";
import { ItemsModel } from "../../commons/itemsModel";
import { CreateElement, VNode } from "vue/types/umd";

export class LeadNeedSurveyCityAndPostalCodeAnswerModel extends LeadNeedSurveyAnswerBaseModel {
    public CityValue: string;
    public PostalCodeValue: number;

    public constructor(cityValue: string, postalCodeValue: number) {
        super();
        this.CityValue = cityValue;
        this.PostalCodeValue = postalCodeValue;
    }

    public create(value: string) : LeadNeedSurveyCityAndPostalCodeAnswerModel {
        return CommonHelper.isNotNullOrUndefined(value) ? JSON.parse(value) as LeadNeedSurveyCityAndPostalCodeAnswerModel : new LeadNeedSurveyCityAndPostalCodeAnswerModel(null, null);
    }

    public render(h: CreateElement, customerAnswerModel: LeadNeedSurveyCityAndPostalCodeAnswerModel, items: ItemsModel[], questionID: number) : VNode {
        return h('v-row', [
            h('v-col', { attrs: { cols: '12', sm: '6'} }, [
                h('v-text-field', {
                    class: 'form-control',
                    attrs: {
                        type: 'tel',
                        id: `postalCode-${questionID}`,
                        placeholder: 'Code postal',
                        value: customerAnswerModel.PostalCodeValue
                    },
                    on: {
                        input: (event: number) : void => {
                            customerAnswerModel.PostalCodeValue = event;
                        }
                    }
                })
            ]),
            h('v-col', { attrs: { cols: '12', sm: '6'} }, [
                h('v-text-field', {
                    class: 'form-control',
                    attrs: {
                        type: 'text',
                        id: `city-${questionID}`,
                        placeholder: 'Ville',
                        value: customerAnswerModel.CityValue
                    },
                    on: {
                        input: (event: string) : void => {
                            customerAnswerModel.CityValue = event;
                        }
                    }
                })
            ]),
        ]);
    }
}