













import Vue from "vue";

export default Vue.extend({
    name: 'InputDatePicker',
    props: {
        value: {
            type: String,
        },
        label: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "JJ/MM/AAAA",
        },
    },
    
    data() {
        return {
            localValue: this.value as string,
            formattedDate: "" as string,
        };
    },

    mounted() {
        this.formatDate(this.value);
    },

    methods: {
        formatDate(date: string) : void {
            if (date) {
                this.formattedDate = new Date(date).toLocaleDateString();
            } else {
                this.formattedDate = '';
            }
        },

        onInput(date: string) : void {
            this.localValue = date;
            this.formatDate(this.localValue);
            
            this.$emit("input", new Date(date));
        },
    },
})
