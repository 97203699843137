import { AddressContext } from "@/infrastructure/common/address.context";
import { AddressResponseModel } from "@/models/address/AddressResponseModel";

export class AddressService {
    private _addressContext: AddressContext;

    public constructor(addressContext: AddressContext) {
        this._addressContext = addressContext;
    }

    public searchAddress(address: string): Promise<AddressResponseModel[]> {
        return this._addressContext.searchAddress(address);
    }
}