import appConfig from "@/app.config";
import ILeadNeedSurveyContext from "@/core/leadNeedSurvey/ileadNeedSurvey.context";
import HttpRepository from "../http.repository";
import { LeadNeedSurveyConfigurationResponse } from "@/models/customer/leadNeedSurvey/leadNeedSurveyConfigurationResponse";
import { SendLeadNeedSurveyModel } from "@/models/customer/leadNeedSurvey/commons/send-lead-need-suvey-model";
import { CreateLeadNeedSurveyCommandResult } from "@/models/customer/leadNeedSurvey/createLeadNeedSurveyResult";

export class LeadNeedSurveyContext extends HttpRepository implements ILeadNeedSurveyContext {
    private webApiUrls = {
        getLeadNeedSurveyConfiguration: `${appConfig.webApiBaseUrl}/LeadSurvey/Configuration`,
        createLeadNeedSurvey: `${appConfig.webApiBaseUrl}/LeadSurvey/Create`,
        updateLeadNeedSurvey: `${appConfig.webApiBaseUrl}/LeadSurvey/Update`
      };
    
    public getLeadNeedSurveyConfiguration(surveyType: string): Promise<LeadNeedSurveyConfigurationResponse> {
        return this.httpGet(`${this.webApiUrls.getLeadNeedSurveyConfiguration}/${surveyType}`);
    }

    public createLeadNeedSurvey(body: SendLeadNeedSurveyModel): Promise<CreateLeadNeedSurveyCommandResult> {
        return this.httpPost(this.webApiUrls.createLeadNeedSurvey, body);
    }

    public updateLeadNeedSurvey(body: SendLeadNeedSurveyModel, leadNeedSurveyID: number): Promise<any> {
        return this.httpPost(`${this.webApiUrls.updateLeadNeedSurvey}?leadNeedSurveyID=${leadNeedSurveyID}`, body);
    }
}