import { CommonHelper } from "@/models/common/common-helper";
import { LeadNeedSurveyAnswerBaseModel } from "../LeadNeedSurveyAnswerBaseModel";
import { ItemsModel } from "../../commons/itemsModel";
import AddressSearch from "@/app/components/common/AddressSearch.vue";
import { CreateElement, VNode } from "vue/types/umd";
import { Address } from "@/models/address/Address";

export class LeadNeedSurveyAddressAnswerModel extends LeadNeedSurveyAnswerBaseModel {
    public Address: string;

    public constructor(address: string) {
        super();
        this.Address = address;
    }

    public create(value: string) : LeadNeedSurveyAddressAnswerModel {
        return CommonHelper.isNotNullOrUndefined(value) ? JSON.parse(value) as LeadNeedSurveyAddressAnswerModel : new LeadNeedSurveyAddressAnswerModel(null);
    }

    public render(h: CreateElement, customerAnswerModel: LeadNeedSurveyAddressAnswerModel, items: ItemsModel[], questionID: number) : VNode {
        return h('div', { class: 'yp-margin-bottom10' }, [
            h(AddressSearch, {
                class: 'form-control',
                attrs: {
                    model: customerAnswerModel.Address,
                },
                on: {
                    change: (event: Address) : void => {
                        customerAnswerModel.Address = `${event.streetNumber} ${event.streetName}`;

                        this.onChangeCityInput(event, questionID);
                        this.onChangePostalCodeInput(event, questionID);
                    }
                }
            })
        ]);
    }

    private onChangeCityInput(event: Address, questionID: number) : void {
        const cityInput = document.getElementById(`city-${questionID}`);

        if (CommonHelper.isNotNullOrUndefined(cityInput)) {
            (cityInput as HTMLInputElement).value = event.city;
            cityInput.dispatchEvent(new Event('input'));
        }
    }

    private onChangePostalCodeInput(event: Address, questionID: number) : void {
        const postalCodeInput = document.getElementById(`postalCode-${questionID}`);

        if (CommonHelper.isNotNullOrUndefined(postalCodeInput)) {
            (postalCodeInput as HTMLInputElement).value = event.postalCode;
            postalCodeInput.dispatchEvent(new Event('input'));
        }
    }
}