export default {
  "Echec de tentative de connexion.": "Connection attempt fails.",
  "Unauthorized": "Your session has expired. Please log in again.",
  "Internal Server Error": "An error has occured during the action.",
  "Enregistrer": "Save",
  "Annuler": "Cancel",
  "Modifier": "Edit",
  "Envoyer": "Send",
  "Confirmer": "Confirm",
  "De": "From",
  "A": "to",
  "HomePage": "Home page",
  "Prenom": "First name",
  "Nom": "Last name",
  "Mail": "Email",
  "Home": "Home",
  "Previous" : "Previous",
  "Next" : "Next",
  "Send" : "Send",
  "bannieretexte": (context) => {
    const url = context.named('url');
    return `Get ready to renew your contract now! Click on <a href="${url}">this link</a>  to tell us more.`;
  },
  AppMenu: {
    "MonEspace": "My Space",
  },
  Locales: {
    en: "English",
    fr: "French",
  },
  UserSettingsDeleteAccount: {
    title: "Account deletion",
    description: "The deletion of your account is irreversible.",
    action: "Delete my account",
    deleteConfirm: "Are you sure you want to delete your Yoopala account ? This action is irreversible.",
    actionConfirmation: "Your account has been deleted.",
  },
  FormValidators: {
    required: "This field is mandatory",
    imageSize: "The size of the image must not exceed 10Mb",
    imageType: "The image format is not valid",
    emailFormat: "Email format is not valid",
  },
  ResetPassword: {
    title: "Reset your password",
    actionConfirmation: "Your password has been changed.",
  },
  ResetPasswordModal: {
    title: "Forgot password",
    description: "An e-mail will be sent to the address.",
    emailLabel: "Email",
    confirmation: "If this email address is known by Yoopala, we will send you an email to reset your password.",
  },
  ErrorPage: {
    defaultMessage: "An error has occured."
  },
  UserSettingsLanguageSelector: {
    title: "Language",
  },
  UserSettingsPassword: {
    title: "Password",
    currentPasswordLabel: "Current password",
    newPasswordLabel: "New password",
    newPasswordConfirmationLabel: "New password confirmation",
    passwordMustBeStrongRule: "Your new password must contain a minimum of 8 characters with at least one uppercase, one lowercase and one number",
    passwordMustNotContainsEmailPrefixRule: "Your new password must not contain your email",
    passwordAndConfirmationMustEqual: "Your new password and its confirmation must be identical",
    action: "Update my password",
    checkNewPasswordConfirmation: "Passwords are not identical",
    actionConfirmation: "Your password has been changed. Please log in again.",
  },
  UserSettingsOptIn: {
    title: "Messages",
    yoopalaSection: "Messages from Yoopala",
    accountActivityEmail: "Emails concerning account activities (essential for the management of your account, you cannot deactivate them)",
    yoopalaInformationEmail: "Information and promotional emails",
    yoopalaInformationSMS: "SMS information and promotions",
    yoopalaInformationPush: "Push information and promotions",
    newAdvertsEmail: "Emails about new ads near you",
    partnersSection: "Messages from Yoopala's partners",
    partnersInformationEmail: "Information and promotional emails",
    partnersInformationSMS: "SMS information and promotions",
    action: "Save",
  },
  UserSettingsPage: {
    title: "Manage my settings",
  },
  BabySitterProfilePage: {
    title: "My profile",
  },
  UnauthorizedPage: {
    message: "You are not authorized to access this page.",
  },
  UserHeaderMenu: {
    "SignOut": "Sign out",
    "UserSettings": "Settings",
  },
  LoginPage: {
    "title": "Log in",
    "email-label": "Email",
    "password-label": "Password",
    "forgot-password": "Forgor password ?",
    "login-action": "Log in",
    "signup": "No account ? Sign Up",
  },
  SponsorshipPage: {
    "title": "Sponsor my friends",
    "hint": 'You can consult the conditions of our sponsorship offer <a href="https://www.yoopala.com/ressources/parrainage-yoopala/" target="_blank"> by clicking here</a>.',
  },
  Resume: {
    "advert": "Advert",
    "advert-placeholder": "Add a title and a description to your advert in order to publish it.",
    "description-label": "Description",
    "title-label": "Title",
    "description-placeholder": "Add a description to your advert, for instance : I'm Julie, I'm 23 years old and live in Paris. I'm currenty studying economics. I got a youth work diploma and I like children. I'm used to keep children since I come from a 4 children family...",
    "title-placeholder": "Specify an advert title, for instance : Julie, awesome 23 years old babysitter",
    titleMessage: (ctx) => {
      const wordCount = ctx.named('wordCount');
      switch (true) {
        case (wordCount === 0):
          return '<span style="color:red">Your title must contain 3 up to 12 words.</span>';
        case (wordCount > 0 && wordCount < 3 || wordCount > 12):
          return `<strong><span style="color:red">${wordCount} word${wordCount > 1 ? 's' : ''}.</span></strong> Your title must contain 3 up to 12 words.`;
        case (wordCount >= 3 && wordCount <= 12):
          return `<strong><span style="color:green">${wordCount} words.</span></strong> Well done, your title is awesome !`;
        default:
          return "";
      }
    },
    descriptionMessage: (ctx) => {
      const wordCount = ctx.named('wordCount');
      switch (true) {
        case (wordCount === 0):
          return '<span style="color:red">Little reminder, your description must contain at least 30 words.</span>';
        case (wordCount > 0 && wordCount < 30):
          return `<strong><span style="color:red">${wordCount} word${wordCount > 1 ? 's' : ''}.</span></strong> Little reminder, your description must contain at least 30 words.`;
        case (wordCount >= 30 && wordCount < 80):
          return `<strong>${wordCount} words.</strong> Well done, your description contains 30 or more words, go up to 80 to get an awesome description.`;
        case (wordCount >= 80):
          return `<strong><span style="color:green">${wordCount} words.</span></strong> Congratulations, your description is awesome !`;
        default:
          return "";
      }
    },
  },
  EducationSection: {
    "level": "Level",
    "sector": "Topic",
    "specific-diplomas": "Specific Diplomas",
    "section-title": "Studies & Diplomas",
    "emptyMessage": "Fill in your level of study and your diplomas.",
    "na": "No information",
  },
  SpokenLanguage: {
    title: "Languages spoken fluently",
    hint: "Only select languages you have an advanced level on."
  },
  Transport: {
    title: "Transport",
    "driving-license": "Driving license",
    "convey-authorization": "Vehicle allowing to convey children"
  },
  ProfileProgressionPanel: {
    completed: "Congratulations, your profile is 100% complete!",
    uncompleted: ctx => `Your profile is ${ctx.named("progression")}% complete. Fill in all the information to increase your chances of being contacted.`,
  },
  Availability: {
    title: "Availabilities",
    summary: (context) => `Available from ${context.named('startTime')} to ${context.named('endTime')}`,
    "add-slot": "Add a time slot",
    emptyMessage: "Configure your hours and days of availability during the week.",
  },
  ProfessionalSituation: {
    title: "Current situation",
  },
  CareServices: {
    title: "Proposed service(s)",
    "standard-care-label": "Childcare at home",
    "housework-service-label": "Housekeeping",
    "hasHomeworkHelpOption-label": "With homework help (primary level)",
    "hasIroningOption-label": "With ironing",
  },
  Experience: {
    title: "Experience | Experiences",
    "experience-type": "Experience type",
    "child-age": "Age of youngest child",
    "child-age-message": "If you look after several children at the same time, indicate the age of the youngest",
    "duration-message": "Period of time during which you kept the child",
    duration: "Childcare duration",
    guardType: "Guard type",
    "guardType-message": "Specify your guard type",
    "nombre-enfant": "Number of children",
    "nombre-enfant-message":"Indicate number of children",
    description: "Description",
    summary: (context) => {
      const expType = context.named('expType');
      const childAge = context.named('childAge');
      const duration = context.named('duration');
      const guardType = context.named('guardType');
      const numChildren = context.named('numChildren');
      let result = "";
      if (expType)
        result += `Experience <strong>${expType}</strong>`
      if (guardType)
        result += `, <strong>${guardType}</strong>,`
      if (numChildren)
        result += ` concerning ${numChildren}`
      if (childAge)
        result += ` <strong>${childAge}</strong>`
      if (duration)
        result += ` for <strong>${duration}</strong>`;
      return result;
    },
    "cannot-edit-experience": "Modification not available, please contact Yoopala",
    "confirm-delete": "Are you sure you want to delete this experience ?",
    emptyMessage: "Add your different babysitting experiences.",
  },
  ConfirmModal: {
    title: "Confirmation",
    message: "Are you sure you want to perform this action?",
  },
  UserProfileAvatar: {
    photoPickerTitle: "Edit my profile picture",
    photoPickerDescription: ctx => `
    <p>
      Your profile picture is very important because it is the first thing
      families look at. This one should give confidence.
    </p>
    <p>Your profile picture is publicly visible.</p>
    <p>
      For more information see our tips and examples
      about photos at
      <a href="${ctx.named('photoGuideUrl')}" target="_blank" class="orange--text"
        >our guide.</a
      >
    </p>`,
    fileUploadLabel: "Pick an image",
    fileUploadRequirements: "Accepted formats : png, jpeg, jpg, gif - Max size : 10Mo",
    deleteConfirm: "Are you sure you want to delete your profile picture ?",
  },
  AdvertPublication: {
    "AdvertPublished": ctx => `Public Profile: You are visible to Yoopala recruiters and on Yoopala.com, <a href="${ctx.named('visibiliteCoordonneesGuideUrl')}" target="_blank" onclick="stopPropagation(event)">learn more.</a>`,
    "AdvertUnpublished": "Private profile: You are visible only to Yoopala recruiters.",
  },
  SocialNetworks: {
    "title": "Social networks",
    "hint": 'Share your link on Facebook, Twitter and where you want with the "Copy link" button.',
    "fb-btn-label": "Share on Facebook",
    "twitter-btn-label": "Share on Twitter",
    "copy-link-btn-label": "Copy link"
  },
  SponsorshipTracking: {
    "title": "Sponsorships tracking"
  },
  Sponsor: {
    "title": "Send an email",
    "send-action": "Send an invitation",
    "action-confirmation": "Your sponsorship request has been sent"
  },
  Header: {
    "dashboard": "Dashboard",
    "searchJobs": "Search jobs",
    "searchAdverts": "Search adverts"
  },
  NotFoundPage: {
    message: "The page you wish to consult cannot be found.",
  },
  EmployeeContractsPage: {
    description: "Find here all your work contracts, your Pôle-emploi certificates and your work certificates.",
    "empty-contract": "No contract to display",
    "Contract": "Contract",
    "From": "From",
    "Since": "Since",
    "To": "to",
    "Status": "Status",
    "your-documents": "Your available documents",
    "empty-document": "No document available",
    "attestation-pole-emploi": "Pôle-emploi certificate",
    "certificat-travail": "Work certificate",
    "your-contract": "Your contract",
    "details": "Details",
    "signature-date": "Signature Date",
    "monthlyHoursVolume": "Monthly Hours Volume",
    "sign-contract-action": "Sign Contract",
    "show-all": "Show all",
  },
  CustomerContractsPage: {
    description: "Find here all your contracts.",
    "empty-contract": "No contract to display",
    "Contract": "Contract",
    "From": "From",
    "Since": "Since",
    "To": "to",
    "Status": "Status",
    "SimpleGuard": "Simple guard",
    "SharedGuard": "Shared guard",
    "details": "Details",
    "monthlyHoursVolume": "Monthly Hours Volume",
    "sign-contract-action": "Sign Contract",
    "show-all": "Show all",
    "fullTimeTypology": "Full time",
    "oneShotTypology": "One shot",
    "standartTypology": "Standart",
    "child": "child",
    "children": "children",
    "Draft": "Draft",
    "ReadyToSend": "Ready to send",
    "WaitingForSignature": "Waiting for signature",
    "Signed": "Signed",
    "Ongoing": "On going",
    "Finished": "Finished",
    "Cancelled": "Cancelled",
    "Refused": "Refused",
    "Expired": "Expired",
    "Error": "Error",
    "HouseworkContract": "Housework",
    "BabysitterContract": "Child care",
    "Cleaning" : "Single housekeeper",
    "CleaningAndIroning" : "Housekeeper + Ironing"
  },
  CustomerContactsPage: {
    "chargee-de-clientele": "Customer Service Representative",
    "chargee-de-clientele-description": "Contact us if your request concerns your contract (modification of your needs, extension, stop of the guards...).",
    "gestionnaire-clientele": "Customer Manager",
    "gestionnaire-clientele-description": "Contact us if your request concerns your schedules, your invoices, your CAF reimbursements, your payments.",
    "reclamation": "Claim",
    "reclamation-description": "Contact information to be used only if our team has not found solutions to your problems.",
    "nous-ecrire": "Write to us",
  },
  CustomerRenewalPage: {
    "rentree-scolaire": "Back to school",
    "renouveler-contrat-septembre": "I would like to renew my contract for September",
    "needContinueWithBS": "I would like to continue with my babysitter(s):",
    "needBSForSummer": "I also need my babysitter(s) for the summer:",
    "commentNeedNewBS": "I need a new babysitter:",
    "needComment": "I know what I need:",
    "checkYes": "Yes",
    "checkNo": "No",
    "demandeenvoyee": "The form has been sent.",
    "demandeerreur": "An error occurred when sending the form.",
    "commentplaceholder": "Details",
    "infosprisesencompte": "Your information has already been taken into account for the new school year. Please contact Yoopala if you wish to make any changes."
  },
  CustomerLeadNeedSurvey: {
    "sendSucceeded" : "Your informations have been successfuly saved !",
    "sendFailed": "Oops ! An error occured, please try again or later."
  },
  EmployeeRenewalPage: {
    "plusdispogardeenfants": "I am no longer available for childcare.",
    "experiencemaldéroulee": "My Yoopala experience didn't go so well",
    "pastravailleragence": "I no longer wish to work in an agency",
    "souhaitepousuivrefamille": "You wish to continue with the family(ies):",
    "dispoannescolaire": "Know your availability for the school year",
    "datedispo": "When will you know your availability?",
    "envoidemande": "Send",
    "infosprisesencompte": "Your information has already been taken into account for the new school year. Please contact Yoopala if you wish to make any changes.",
    "demandeenvoyee": "Thank you, your request has been sent!",
    "poursuivregardescolaire": (context) => {
      const currentYear = context.named('currentYear');
      return `Would you like to continue the shifts at the start of the new school year ${currentYear}
      with
      Yoopala
      ?`
    },
      "rentreescolairetitre": (context) => {
        const currentYear = context.named('currentYear');
        const nextYear = context.named('nextYear');
        return `Back to school ${currentYear} - ${nextYear}`
    },
    "quelleraison": "Why?",
    "famille": (context) => { 
      const familyName = context.named('familyName');
      return `Family ${familyName}`;
  }
  },
  AppFooter: {
    "description": "Created in 2009, Yoopala is a state-approved French company specialized in home childcare. With more than 7,000 employees and nearly 700,000 hours of care performed each year, Yoopala is one of the major players in its field of activity.",
    "Société": "Company",
    "Assistance en ligne": "Online support",
    "Nous contacter": "Contact us",
    "Carrières": "Careers",
    "Mentions légales": "Legal information and general conditions",
    "Politique de protection des données": "Data privacy policy",
    "Informations": "Informations",
    "legal": "© YOOPALA - Couverture Nationale dont Paris et IDF, Marseille, Lyon, Toulouse, Bordeaux, Nice, Lille, Nantes. Siège social : 19 boulevard Malesherbes, 75008 PARIS. S.A. au capital de 1.041.225 € - R.C.S. PARIS 488 791 203 - Tel : 09 88 77 66 80 - Fax : 01 77 75 45 44 - Web : yoopala.com. Yoopala Services dispose d’un agrément délivré par la DIRECCTE UT 75 (35, rue de la gare-CS 60003 - 75144 Paris Cedex 19) sous le N°SAP 488 791 203.",
  },
  Catalogs: {
    BabysitterAgencySections: {
      jobs: {
        title: "See jobs",
        description: "Look for available jobs in your area and apply",
      },
      contract: {
        title: "My work contracts",
        description: "Find and read your contract documents",
      },
      prestation: {
        title: "My prestations",
        description: "Find and manage your prestations",
      },
      payslip: {
        title: "My payslips",
        description: "Find and read your payslips",
      },
      toread: {
        title: "To read",
        description: "Find and manage your legal documents",
      },
      contacts: {
        title: "My contacts",
        description: "Access your Yoopala adviser contact details",
        info: "Find all your Yoopala contacts here",
        arh: {
          title : "Human Resources Assistant",
          desc: "Contact us only if your request concerns your personnel file (sick leave, time recording, payroll, contract, your contact person, etc.)",
          mail: "administratif@yoopala.com",
        },
        refSecteur: {
          title : "Area contact",
          desc: "To be contacted only if your request concerns questions relating to the childcare service: safety, early learning, hygiene, children's well-being...",
          mail: "administratif@yoopala.com",
        },
        reclamation: {
          title : "Claim",
          desc: "This e-mail address is to be used only if our teams have not found a solution to your problem.",
          mail: "assistance@yoopala.com",
        },
        numUrgence: {
          title : "Emergency phone number",
          desc: "Contact only in the event of an accident or serious problems with the child during the care period",
          tel: "06.08.99.41.56",
        },
        adresseARH: {
          title : "Human Resources Administration address",
          service: "YOOPALA Services",
          adresse: "2740 Chemin Saint Bernard",
          codePostal: "06220 VALLAURIS",
        },
        ecrire: {
          title : "Write to us",
          service: "YOOPALA SERVICES",
          cs: "CS 10281",
          codePostal: "06227 VALLAURIS CEDEX",
        }
      },
      sponsorship: {
        title: "Sponsorship",
        description: "Sponsor your friends and contacts",
      },
      profile: {
        title: "My profile",
        description: "Manage your personal information required for your profile qualification",
      },
    },
    CustomerAgencySections: {
      contract: {
        title: "My contracts",
        description: "Access your contracts",
      },
      prestation: {
        title: "My prestations",
        description: "Access and manage your prestations",
      },
      invoices: {
        title: "My invoices",
        description: "Pay your invoices online and access invoices history"
      },
      payments: {
        title: "My payments",
        description: "Access your payments history"
      },
      "fiscal-certificates": {
        title: "Fiscal certifications",
        description: "Access your fiscal certificates"
      },
      contacts: {
        title: "My contacts",
        description: "Access your Yoopala adviser contact details",
      },
      sponsorship: {
        title: "Sponsorship",
        description: "Sponsor your friends and contacts",
      },
      profiles: {
        title: "Profiles",
        description: "Access available profiles in your area"
      },
      estimations: {
        title: "My estimations",
        description: "Access your estimations"
      }
    }
  },
  Enums: {
    CriteriaEducationLevelEnum: {
      1258: "BEPC",
      49: "CAP/BEP",
      1259: "BAC",
      50: "BAC +1",
      51: "BAC +2",
      52: "BAC +3",
      53: "BAC +4",
      54: "BAC +5",
      1260: "BAC +8 / Doctorat",
      1262: "No diploma",
    },
    CriteriaEducationThemeEnum: {
      55: "Agriculture",
      56: "Architecture",
      57: "Art",
      58: "Crafts",
      59: "Audiovisual",
      60: "Banking and Insurance",
      61: "Construction",
      62: "Hairdressing",
      63: "Commerce",
      64: "Communication",
      65: "Accounting",
      66: "Law",
      67: "Economics",
      68: "Environment",
      69: "Real Estate",
      70: "Language",
      71: "Marketing",
      72: "Mathematics",
      73: "Advertising",
      74: "Quality",
      75: "Human Resources",
      76: "Secretariat",
      77: "Sport",
      78: "Tourism",
      79: "Sales",
      80: "Other",
    },
    CriteriaDiplomasEnum: {
      1241: "Maternal Assistant Certification",
      1242: "PSC1 or STT training",
      1247: "Diploma in the Childcare Sector",
      1248: "Diploma in the care and support sector",
      1249: "Diploma in the animation sector",
      1250: "Other",
    },
    BabySitterExperienceCategorieEnum: {
      0: "within my family",
      1: "in a family's home",
      2: "in nursery",
      3: "in a children's activity center",
      4: "in a summer camp",
      5: "during events (weddings, birthdays...)",
      6: "in agency",
      7: "at school"
    },
    ChildrenAgeCategoryEnum: {
      0: "Less than 6 months old",
      1: "Between 6 and 11 months",
      3: "Between 1 and 2 years old",
      6: "Between 3 and 5 years old",
      7: "More than 6 years old"
    },
    BabySitterExperienceDurationEnum: {
      3: "From 0 to 3 months",
      6: "From 4 to 5 months",
      12: "From 6 to 11 months",
      13: "More than 1 year"
    },
    CriteriaSpokenLanguageEnum: {
      106: "French",
      1: "English",
      4: "German",
      2: "Spanish",
      3: "Italian",
      111: "Portuguese",
      112: "Russian",
      113: "Arabian",
      114: "Mandarin",
      1251: "Dutch",
      116: "Other languages"
    },
    CriteriaChildCareSkillsEnum: {
      5: "Homeworks",
      120: "Homeworks",
      107: "Give the bath",
      1252: "Change a child",
      109: "Cook",
      108: "Give the meal",
      1253: "Feed a baby",
      110: "Activities and games"
    },
    DayOfWeekEnum: {
      0: "Sunday",
      1: "Monday",
      2: "Tuesday",
      3: "Wednesday",
      4: "Thursday",
      5: "Friday",
      6: "Saturday"
    },
    BabySitterAvatarStateEnum: {
      0: "Pending Validation",
      1: "Validated",
      2: "Rejected",
    },
    SponsorshipStateEnum: {
      0: "Mail sent",
      1: "Sponsored party registered"
    },
    BabySitterProfessionalSituationEnum: {
      0: "Undefined",
      1: "Student",
      2: "Looking for a full-time job",
      3: "Looking for a part-time job",
      4: "Employee",
      5: "Retired",
      6: "In vocational training",
      99: "Other"
    },
    BabySitterExperienceGuardTypeEnum: {
      0: "Before/after school",
      1: "During the day",
      2: "In the evening",
      3: "During the week-end",
    },
    ChildrenNumCategoryEnum:{
      1: "1 child",
      2: "2 children",
      3: "3 children",
      4: "4 children and more"
    },
    DaysOfWeekEnum: {
      0: 'Monday',
      1: 'Tuesday',
      2: 'Wednesday',
      3: 'Thursday',
      4: 'Friday',
      5: 'Saturday',
      6: 'Sunday',
    }
  },
  Components: {
    AddressSearch: {
      charRemains: 'remains characters to launch search.'
    }
  }
};
