import { CommonHelper } from "@/models/common/common-helper";
import { LeadNeedSurveyAnswerBaseModel } from "../LeadNeedSurveyAnswerBaseModel";
import { ItemsModel } from "../../commons/itemsModel";
import { CreateElement, VNode } from "vue/types/umd";

export class LeadNeedSurveyUniqueDropdownAnswerModel extends LeadNeedSurveyAnswerBaseModel {
    public UniqueDropdownValue: number;
    private _defaultItems = [new ItemsModel('true', 'Oui'), new ItemsModel('false', 'Non')] as ItemsModel[];

    public constructor(uniqueDropdownValue: number) {
        super();
        this.UniqueDropdownValue = uniqueDropdownValue;
    }

    public create(value: string) : LeadNeedSurveyUniqueDropdownAnswerModel {
        return CommonHelper.isNotNullOrUndefined(value) ? JSON.parse(value) as LeadNeedSurveyUniqueDropdownAnswerModel : new LeadNeedSurveyUniqueDropdownAnswerModel(null);
    }

    public render(h: CreateElement, customerAnswerModel: LeadNeedSurveyUniqueDropdownAnswerModel, items: ItemsModel[]) : VNode {
        items = items?.length === 0 ? this._defaultItems : items;

        return h('div', { class: 'yp-margin-bottom10' }, [
            h('v-select', {
                class: 'form-control',
                style: { width: '100%' },
                attrs: { 
                    items: items,
                    value: customerAnswerModel.UniqueDropdownValue,
                    'item-text': "Value",
                    'item-value': "Key" 
                },
                on: {
                    input: (event: number) : void => {
                        customerAnswerModel.UniqueDropdownValue = event;
                    }
                }
            })
        ]);
    }
}