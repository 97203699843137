import { CommonHelper } from "@/models/common/common-helper";
import { LeadNeedSurveyAnswerBaseModel } from "../LeadNeedSurveyAnswerBaseModel";
import { ItemsModel } from "../../commons/itemsModel";
import { CreateElement, VNode } from "vue/types/umd";

export class LeadNeedSurveyMultipleDropdownAnswerModel extends LeadNeedSurveyAnswerBaseModel {
    public MultipleDropdownValue: number[];

    public constructor(multipleDropdownValue: number[]) {
        super();
        this.MultipleDropdownValue = multipleDropdownValue;
    }

    public create(value: string) : LeadNeedSurveyMultipleDropdownAnswerModel {
        return CommonHelper.isNotNullOrUndefined(value) ? JSON.parse(value) as LeadNeedSurveyMultipleDropdownAnswerModel : new LeadNeedSurveyMultipleDropdownAnswerModel(null);
    }

    public render(h: CreateElement, customerAnswerModel: LeadNeedSurveyMultipleDropdownAnswerModel, items: ItemsModel[]) : VNode {
        return h('div', { class: 'yp-margin-bottom10' }, [
            h('v-select', {
                class: 'form-control',
                style: { width: '100%' },
                attrs: { 
                    items: items,
                    multiple: true,
                    value: customerAnswerModel.MultipleDropdownValue,
                    'item-text': "Value",
                    'item-value': "Key"
                },
                on: {
                    input: (event: number[]) : void => {
                        customerAnswerModel.MultipleDropdownValue = event;
                    }
                }
            })
        ]);
    }
}