import { CommonHelper } from "@/models/common/common-helper";
import { LeadNeedSurveyAnswerBaseModel } from "../LeadNeedSurveyAnswerBaseModel";
import { CreateElement, VNode } from "vue/types/umd";

export class LeadNeedSurveyOneTextFieldAnswerModel extends LeadNeedSurveyAnswerBaseModel {
    public OneTextFieldValue: string;

    public constructor(oneTextFieldValue: string) {
        super();
        this.OneTextFieldValue = oneTextFieldValue;
    }

    public create(value: string) : LeadNeedSurveyOneTextFieldAnswerModel {
        return CommonHelper.isNotNullOrUndefined(value) ? JSON.parse(value) as LeadNeedSurveyOneTextFieldAnswerModel : new LeadNeedSurveyOneTextFieldAnswerModel(null);
    }

    public render(h: CreateElement, customerAnswerModel: LeadNeedSurveyOneTextFieldAnswerModel) : VNode {
        return h('v-text-field', {
            class: 'form-control',
            attrs: { 
                type: 'text',
                value: customerAnswerModel.OneTextFieldValue
            },
            on: {
                input: (event: string) : void => {
                    customerAnswerModel.OneTextFieldValue = event;
                }
            }
        });
    }
}