export enum ResultTypeEnum {
    UniqueDropdown = 1,
    NumericField = 2,
    MultipleDropdown = 4,
    TwoTextField = 8,
    DatePicker = 16,
    OneTextField = 32,
    Address = 64,
    CityAndPostalCode = 128,
    Availabilities = 256,
    Comment = 512,
    ChildNumbers = 1024,
}