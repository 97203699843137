import { CommonHelper } from "@/models/common/common-helper";
import { DayOfWeekEnum } from "../../enums/day-of-week.enum";
import { LeadNeedSurveyAnswerBaseModel } from "../LeadNeedSurveyAnswerBaseModel";
import { ItemsModel } from "../../commons/itemsModel";
import { getEnumValues } from "@/core/helpers/enum.helper";
import { translate } from "@/app/helpers/plugins/i18n";
import { CreateElement, VNode } from "vue/types/umd";

export class LeadNeedSurveyAvailabilitiesAnswerModel extends LeadNeedSurveyAnswerBaseModel {
    public Availabilities: ScheduledDays[];
    private _component: any;

    public constructor(availabilities: ScheduledDays[], component: any = null) {
        super();
        this.Availabilities = availabilities;
        this._component = component ?? this._component;
    }

    public create(value: string) : LeadNeedSurveyAvailabilitiesAnswerModel {
        const tempValue = CommonHelper.isNotNullOrUndefined(value) ? JSON.parse(value) as LeadNeedSurveyAvailabilitiesAnswerModel : new LeadNeedSurveyAvailabilitiesAnswerModel([new ScheduledDays()]);
        
        if (tempValue.Availabilities.length < 1) {
            tempValue.Availabilities.push(new ScheduledDays());
        }
        
        return tempValue;
    }

    public render(h: CreateElement, customerAnswerModel: LeadNeedSurveyAvailabilitiesAnswerModel) : VNode {
        const schedules: VNode[] = [];
        const selectOptions = getEnumValues(DayOfWeekEnum).map((v: number) => new ItemsModel(v.toString(), `${translate(`Enums.DaysOfWeekEnum.${v}`)}`));

        for (const availability in customerAnswerModel.Availabilities) {
            schedules.push(this.renderSchedule(h, Number(availability), selectOptions , customerAnswerModel));
        }

        return h('div', {
            class: 'mb-4',
            attrs: {
                key: `${this._component.availabilitiesRender}`
            }
        },
        schedules);
    }

    private renderSchedule(h: CreateElement, availability: number, selectOptions: ItemsModel[], customerAnswerModel: LeadNeedSurveyAvailabilitiesAnswerModel) : VNode {
        return h('v-row', [
            h('v-col', { attrs: { cols: '12', sm: '5' } }, [
                h('v-select', {
                    class: 'form-control',
                    style: { width: '100%' },
                    attrs: { 
                        items: selectOptions, 
                        multiple: true, 
                        value: customerAnswerModel.Availabilities[availability].Days,
                        'item-text': 'Value',
                        'item-value': 'Key'
                    },
                    on: {
                        input: (event: DayOfWeekEnum[]) : void => {
                            const timePicker = document.getElementById(`time-${availability}`);

                            timePicker.style.display = event.length > 0 ? 'block' : 'none';
                            customerAnswerModel.Availabilities[availability].Days = event;
                        }
                    }
                })
            ]),
            h('v-col',
                {
                    style: { display: customerAnswerModel.Availabilities[availability].Days.length > 0 ? 'block' : 'none' },
                    attrs: {
                        id: `time-${availability}`,
                        cols: '12',
                        sm: '7'
                    }
                },
                [
                    h('v-row',
                        [
                            h('v-col', {
                                attrs: { cols: '12', sm: '5'}
                            },
                                [
                                    h('v-text-field', {
                                        class: 'form-control time-picker',
                                        attrs: {
                                            type: 'time',
                                            value: customerAnswerModel.Availabilities[availability].StartTime
                                        },
                                        on: {
                                            input: (event: Date) : void => {
                                                customerAnswerModel.Availabilities[availability].StartTime = event;
                                            }
                                        }
                                    })
                                ]),
                            h('v-col', {
                                attrs: { cols: '12', sm: '5' }
                            },
                                [
                                    h('v-text-field', {
                                        class: 'form-control time-picker',
                                        attrs: {
                                            type: 'time',
                                            value: customerAnswerModel.Availabilities[availability].EndTime
                                        },
                                        on: {
                                            input: (event: Date) : void => {
                                                customerAnswerModel.Availabilities[availability].EndTime = event;
                                            }
                                        }
                                    })
                                ]),
                            h('v-col', {
                                attrs: { cols: '12', sm: '2' },
                                style: { 'align-content': 'center'}
                            },
                                [
                                    h('v-row', [
                                        h('v-col', { attrs: {cols: '12', sm: '6' } }, [
                                            h('v-btn', {
                                                attrs: { 
                                                    color: 'error',
                                                    icon: true,
                                                    outlined: true
                                                 },
                                                style: {
                                                    display: customerAnswerModel.Availabilities.length > 1 ? 'block' : 'none'
                                                },
                                                on: {
                                                    click: (event: Event) : void => {
                                                        event.preventDefault();
                                                        customerAnswerModel.Availabilities.splice(availability, 1);
                                                        this._component.availabilitiesRender++;
                                                    }
                                                }
                                            }, [
                                                h('v-icon', 'mdi-trash-can-outline')
                                            ])
                                        ]),
                                        h('v-col', { attrs: {cols: '12', sm: '6' } }, [
                                            h('v-btn', {
                                                attrs: { 
                                                    color: 'green',
                                                    icon: true,
                                                    outlined: true
                                                },
                                                style: {
                                                    display: customerAnswerModel.Availabilities.length - 1 == availability ? 'block' : 'none'
                                                },
                                                on: {
                                                    click: () : void => {
                                                        const newAvailabilities = [...customerAnswerModel.Availabilities, new ScheduledDays()];
                                                        customerAnswerModel.Availabilities = newAvailabilities;
                                                        this._component.availabilitiesRender++;
                                                    }
                                                }
                                            }, [
                                                h('v-icon', 'mdi-plus')
                                            ])
                                        ])
                                    ])
                                    
                                ])
                        ]),
                ]),
        ]);
    }
}

class ScheduledDays {
    public Days: DayOfWeekEnum[];
    public StartTime: Date;
    public EndTime: Date;

    public constructor(days: DayOfWeekEnum[] = [], startTime: Date = null, endTime: Date = null) {
        this.Days = days;
        this.StartTime = startTime; 
        this.EndTime = endTime; 
    }
}