






















































import Vue from "vue";
import appFactories from "@/app.factories";
import { LeadNeedSurveyConfigurationResponse } from "@/models/customer/leadNeedSurvey/leadNeedSurveyConfigurationResponse";
import { LeadNeedSurveyQuestionBaseModel } from "@/models/customer/leadNeedSurvey/questions/leadNeedSurveyQuestionBaseModel";
import { getEnumFlags } from "@/core/helpers/enum.helper";
import { LeadNeedSurveyQuestionComponentFactory } from "@/models/customer/leadNeedSurvey/questions/factory/leadNeedSurveyQuestionComponent.factory";
import NavigablePage from "@/app/components/common/NavigablePage.vue";
import { ResultTypeEnum } from "@/models/customer/leadNeedSurvey/enums/resultTypeEnum";
import { LeadSurveyCategoryModel } from "@/models/customer/leadNeedSurvey/leadSurveyCategoryModel";
import { SendLeadNeedSurveyCommandBodyModel } from "@/models/customer/leadNeedSurvey/commons/send-lead-need-survey-command-body-model";
import { SendLeadNeedSurveyModel } from "@/models/customer/leadNeedSurvey/commons/send-lead-need-suvey-model";
import { CreateLeadNeedSurveyCommandResult } from "@/models/customer/leadNeedSurvey/createLeadNeedSurveyResult";
import { CommonHelper } from "@/models/common/common-helper";
import { TranslateResult } from "vue-i18n";
import { CreateElement, VNode } from "vue/types/umd";
import { QuestionRenderModel } from "@/models/customer/leadNeedSurvey/questions/QuestionRenderModel";

export default Vue.extend({
    components: { NavigablePage },
    name: "CustomerLeadNeedSurvey",

    data() {
        return {
            isLoading: true as boolean,
            model: null as LeadNeedSurveyConfigurationResponse,
            componentFactory: new LeadNeedSurveyQuestionComponentFactory(this),
            availabilitiesRender: 0 as number,
            childsRender: 0 as number,
            succeeded: false as boolean,
            failed: false as boolean,
            currentStep: 0 as number,
            totalSteps: 0 as number,
            cachedComponents: {} as { [step: number]: QuestionRenderModel[] },
            leadNeedSurveyID: null as number,
            currentMaxQuestionID: 0 as number,
            prevMaxQuestionID: 0 as number
        };
    },

    async mounted() {
        await this.loadLeadNeedSurveyConfiguration();
    },

    methods: {
        async loadLeadNeedSurveyConfiguration() : Promise<void> {
            try {
                this.isLoading = true;

                this.model = await appFactories.services.leadNeedSurvey.getLeadNeedSurveyConfiguration(this.$route.params.surveyType);

                this.totalSteps = Object.keys(this.model.Categories).length - 1;

                this.buildCachedQuestions(this.model.Categories, this.model.SubQuestionIds);
            } catch(e) {
                this.failed = true;
            } finally {
                this.isLoading = false;
            }
        },

        async createLeadNeedSurveyAnswers(body: SendLeadNeedSurveyModel) : Promise<number> {
            let result = null as CreateLeadNeedSurveyCommandResult;

            try {
                this.isLoading = true;
                result = await appFactories.services.leadNeedSurvey.createLeadNeedSurvey(body);
            } catch(e) {
                this.failed = true;
            } finally {
                this.isLoading = false;
            }

            return result?.leadNeedSurveyID;
        },

        async updateLeadNeedSurveyAnswers(body: SendLeadNeedSurveyModel, leadneedSurveyID: number) : Promise<void> {
            try {
                this.isLoading = true;
                await appFactories.services.leadNeedSurvey.updateLeadNeedSurvey(body, leadneedSurveyID);
                
                if (this.isLastStep) {
                    this.succeeded = true;
                }

            } catch(e) {
                this.failed = true;
            } finally {
                this.isLoading = false;
            }
        },

        questionBuilder(question: LeadNeedSurveyQuestionBaseModel) : QuestionRenderModel {
            const colMaxWidth = 12 as number;

            return {
                render: (h: CreateElement) : VNode => {
                    const elements = [] as VNode[];
                    const deconstructTypes = getEnumFlags<ResultTypeEnum>(ResultTypeEnum, question.Type);
                    const size = colMaxWidth / deconstructTypes.length;

                    for (const type of deconstructTypes) {
                        const element = this.componentFactory.renderQuestion(type, question, h);
                        elements.push(h('v-col', {  class: 'mt-0 pt-0', attrs: { cols: colMaxWidth, sm: size} }, [
                            element
                        ]));
                    }

                    return h('v-row', [
                        h('v-col', { class: 'mb-0 pb-0', attrs: { cols: '12', sm: '12'} }, question.Text),
                        elements
                    ]);
                }
            };
        },

        prepareBody: function (category: number): SendLeadNeedSurveyCommandBodyModel[] {
            let body = [] as SendLeadNeedSurveyCommandBodyModel[];
            let answers = this.componentFactory.Answers;

            for (let answer in answers) {
                body.push(new SendLeadNeedSurveyCommandBodyModel(Number(answer), JSON.stringify(answers[answer]), category));
            }

            return body;
        },

        async onSend() : Promise<void> {
            let body = new SendLeadNeedSurveyModel(this.prepareBody(this.currentStep), null);
            this.currentMaxQuestionID = body.Answers.length;

            body.Answers = body.Answers.slice(this.prevMaxQuestionID, this.currentMaxQuestionID);

            if (CommonHelper.isNullOrUndefined(this.leadNeedSurveyID)) {
                this.leadNeedSurveyID = await this.createLeadNeedSurveyAnswers(body);
            }
            else {
                await this.updateLeadNeedSurveyAnswers(body, this.leadNeedSurveyID);
            }

            this.prevMaxQuestionID = this.currentMaxQuestionID;
        },

        buildCachedQuestions(categories: LeadSurveyCategoryModel[], subQuestionIds: number[]) : void {
            for (const category in categories) {
                this.cachedComponents[category] = categories[category].Questions.map((question) => {
                    if (!subQuestionIds.includes(question.ID)) {
                        return this.questionBuilder(question);
                    }
                });
            }
        },

        async onNext() : Promise<void> {
            await this.onSend();
            this.currentStep++;
        },

        onPrevious() : void {
            this.currentStep--;
        }
    },

    computed: {
        nextButtonLabel() : TranslateResult {
            return this.isLastStep ? this.$t('Send') : this.$t('Next');
        },

        isLastStep() : boolean {
            return this.currentStep == this.totalSteps;
        }
    }
})
