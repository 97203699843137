import * as _ from "lodash";

export function getEnumValues(enumType) {
  return Object.keys(enumType)
    .filter(item => isNaN(Number(item)))
    .map(item => enumType[item]);
}

export function getEnumValuesOrderByLabel(enumType, $t, $tBaseKey): any[] {
  return _.sortBy(
    getEnumValues(enumType).map((v) => ({
      value: v,
      label: $t(`${$tBaseKey}.${v}`),
    })),
    ["label"]
  ).map((_) => _.value);
}

export function getEnumFlags<EnumType extends number>(e : Record<string, EnumType | string>, value : EnumType) : EnumType[] {
  const isFlag = (arg : number) : boolean => {
    const isNumber = !Number.isNaN(arg);
    return isNumber && isPowerOfTwo(arg);
  };

  const enumFlags = [] as EnumType[];

  Object.keys(e).forEach(key => {
    const nKey = Number(key);
    if ((value & nKey) && isFlag(nKey)) {
      enumFlags.push(nKey as EnumType);
    }
  });

  return enumFlags;
}

const isPowerOfTwo = (x: number): boolean => {
  return x != 0 && (x & (x - 1)) == 0;
};