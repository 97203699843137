import { CommonHelper } from "@/models/common/common-helper";
import { LeadNeedSurveyQuestionBaseModel } from "../../questions/leadNeedSurveyQuestionBaseModel";
import { LeadNeedSurveyOneTextFieldAnswerModel } from "./one-text-field-answer-model";
import { LeadNeedSurveyAnswerBaseModel } from "../LeadNeedSurveyAnswerBaseModel";
import { ItemsModel } from "../../commons/itemsModel";
import InputDatePicker from "@/app/components/common/InputDatePicker.vue";
import { CreateElement, VNode } from "vue/types/umd";

export class LeadNeedSurveyChildNumbersdAnswerModel extends LeadNeedSurveyAnswerBaseModel {
    public MultipleAnswers: LeadNeedSurveyChildsAnswerModel[];
    private _component: any;

    public constructor(multipleAnswers: LeadNeedSurveyChildsAnswerModel[], component: any = null) {
        super();
        this.MultipleAnswers = multipleAnswers;
        this._component = component ?? this._component;
    }

    public create(value: string) : LeadNeedSurveyChildNumbersdAnswerModel {
        return CommonHelper.isNotNullOrUndefined(value) ? JSON.parse(value) as LeadNeedSurveyChildNumbersdAnswerModel : new LeadNeedSurveyChildNumbersdAnswerModel([new LeadNeedSurveyChildsAnswerModel()]);
    }

    public render(h: CreateElement, customerAnswerModel: LeadNeedSurveyChildNumbersdAnswerModel, items: ItemsModel[], questionID: number, questionText: string, subQuestions: LeadNeedSurveyQuestionBaseModel[]) : VNode {
        const childs = [] as VNode[];

        for (const child in customerAnswerModel.MultipleAnswers) {
            let childAnswer = null as VNode;

            if (Number(child) > 0) {
                childAnswer = h('div', [
                    h('v-divider', { class: 'mt-2 mb-4' }),
                    h('label', questionText),
                    this.renderChilds(h, Number(child), subQuestions, customerAnswerModel)
                ]);
            }
            else {
                childAnswer = this.renderChilds(h, Number(child), subQuestions, customerAnswerModel);
            }

            childs.push(childAnswer);
        }

        return h('div', { class: 'mb-4', attrs: { key: `${this._component.childsRender}` } }, childs);
    }

    private renderChilds(h: CreateElement, child: number, subQuestions: LeadNeedSurveyQuestionBaseModel[], customerAnswerModel: LeadNeedSurveyChildNumbersdAnswerModel) : VNode {
        return h('v-row', [
            h('v-col', { attrs: { cols: '12', sm: '6'} }, [
                h('v-row', [
                    h('v-col', { attrs: { cols: '12', sm: '6'} }, [
                        h('v-text-field', {
                            class: 'form-control',
                            attrs: {
                                type: 'text',
                                placeholder: 'Prénom',
                                value: customerAnswerModel.MultipleAnswers[child].ChildAnswer.FirstName,
                            },
                            on: {
                                input: (event: string) : void => {
                                    customerAnswerModel.MultipleAnswers[child].ChildAnswer.FirstName = event;
                                }
                            }
                        })
                    ]),
                    h('v-col', { attrs: { cols: '12', sm: '6'} }, [
                        h('v-text-field', {
                            class: 'form-control',
                            attrs: { 
                                type: 'text',
                                placeholder: 'Nom',
                                value: customerAnswerModel.MultipleAnswers[child].ChildAnswer.LastName,
                            },
                            on: {
                                input: (event: string) : void => {
                                    customerAnswerModel.MultipleAnswers[child].ChildAnswer.LastName = event;
                                }
                            }
                        })
                    ])
                ])
            ]),
            h('v-col', { attrs: { cols: '12', sm: '4'} }, [
                h(InputDatePicker, {
                    class: 'form-control',
                    attrs: {
                        value: customerAnswerModel.MultipleAnswers[child].ChildAnswer.BirthDate,
                        placeholder: 'JJ/MM/AAAA'
                    },
                    on: {
                        input: (event: Date) : void => {
                            customerAnswerModel.MultipleAnswers[child].ChildAnswer.BirthDate = event;
                        }
                    }
                })
            ]),
            h('v-col', { attrs: { cols: '12', sm: '2'}, style: { 'align-content': 'center'} }, [
                h('v-row', [
                    h('v-col', { attrs: {cols: 'auto' } }, [
                        h('v-btn', {
                            class: 'align-center',
                            attrs: {
                                color: 'error',
                                icon: true,
                                outlined: true
                            },
                            style: {
                                display: customerAnswerModel.MultipleAnswers.length > 1 ? 'block' : 'none',
                            },
                            on: {
                                click: (event: Event) : void => {
                                    event.preventDefault();
                                    customerAnswerModel.MultipleAnswers.splice(child, 1);
                                    this._component.childsRender++;
                                }
                            }
                        }, [
                            h('v-icon', 'mdi-trash-can-outline')
                        ])
                    ]),
                    h('v-col', { attrs: { cols: 'auto' } }, [
                        h('v-btn', {
                            class: 'align-center',
                            attrs: {
                                color: 'green',
                                icon: true,
                                outlined: true
                            },
                            style: {
                                display: customerAnswerModel.MultipleAnswers.length - 1 == child ? 'block' : 'none',
                            },
                            on: {
                                click: () : void => {
                                    const newAnswer = [...customerAnswerModel.MultipleAnswers, new LeadNeedSurveyChildsAnswerModel(new Child(null, null, null, null, subQuestions.map((_: LeadNeedSurveyQuestionBaseModel) => _.ID)))];
        
                                    customerAnswerModel.MultipleAnswers = newAnswer;
                                    this._component.childsRender++;
                                }
                            }
                        }, [
                            h('v-icon', 'mdi-plus')
                        ])
                    ]),
                ])
            ])
            ,
            h('v-col', { attrs: { cols: '12', sm: '12'} }, [
                this.renderSubQuestion(h, customerAnswerModel.MultipleAnswers[child].ChildAnswer.SubAnswers, subQuestions)
            ])
        ]);
    }

    private renderSubQuestion(h: CreateElement, subAnswers: any[], subQuestions: LeadNeedSurveyQuestionBaseModel[]) : VNode {
        const render = [];

        for (const questionIndex in subQuestions) {
            const size = Number(questionIndex + 1) % 2 ? 6 : 12;
            const question = subQuestions[questionIndex];

            let answerModel = question.AnswerModel;

            if (CommonHelper.isNotNullOrUndefined(subAnswers)) {
                const subAnswer = subAnswers.find((_: any) => _.ID == question.ID);

                if (subAnswer) {
                    question.Answer = JSON.stringify(subAnswer.Answer);
                    answerModel = subAnswer.Answer;
                }
            }

            render.push(h('v-col', {
                    key: question.Text,
                    attrs: { cols: '12', sm: size}
                }, [
                    h('label', { class: 'control-label', style: { 'min-height': '5em' } }, question.Text),
                    h('v-text-field', {
                        class: 'form-control',
                        attrs: {
                            type: 'text',
                            value: (answerModel as LeadNeedSurveyOneTextFieldAnswerModel).OneTextFieldValue
                        },
                        on: {
                            input: (event: string) : void => {
                                (answerModel as LeadNeedSurveyOneTextFieldAnswerModel).OneTextFieldValue = event;
                            }
                        }
                    })
                ])
            );
        }

        return h('div', [h('v-row', render)]);
    }
}

class LeadNeedSurveyChildsAnswerModel {
    public ChildAnswer: Child;

    public constructor(child: Child = null) {
        this.ChildAnswer = child ?? new Child(null);
    }
}

class Child {
    public ChildID: number;
    public FirstName: string;
    public LastName: string;
    public BirthDate: Date;
    public SubAnswers: MultipleAnswerModel[] = [];

    public constructor(childID: number, firstName: string = null, lastName: string = null, birthDate: Date = null, subAnswerIds: number[] = null) {
        this.ChildID = childID;
        this.FirstName = firstName;
        this.LastName = lastName;
        this.BirthDate = birthDate;

        if (subAnswerIds != null && subAnswerIds.length > 0) {
            subAnswerIds.forEach((_: number) => this.SubAnswers.push(new MultipleAnswerModel(_, new LeadNeedSurveyOneTextFieldAnswerModel(null))));
        }

    }
}

class MultipleAnswerModel {
    public ID: number;
    public Answer: LeadNeedSurveyAnswerBaseModel;

    public constructor(id: number, answer: LeadNeedSurveyAnswerBaseModel) {
        this.ID = id;
        this.Answer = answer;
    }
}