import { CommonHelper } from "@/models/common/common-helper";
import { LeadNeedSurveyAnswerBaseModel } from "../LeadNeedSurveyAnswerBaseModel";
import { CreateElement, VNode } from "vue/types/umd";

export class LeadNeedSurveyCommentAnswerModel extends LeadNeedSurveyAnswerBaseModel {
    public CommentValue: string;

    public constructor(commentValue: string) {
        super();
        this.CommentValue = commentValue;
    }

    public create(value: string) : LeadNeedSurveyCommentAnswerModel {
        return CommonHelper.isNotNullOrUndefined(value) ? JSON.parse(value) as LeadNeedSurveyCommentAnswerModel : new LeadNeedSurveyCommentAnswerModel(null);
    }

    public render(h: CreateElement, customerAnswerModel: LeadNeedSurveyCommentAnswerModel) : VNode {
        return h('v-textarea', {
            class: 'form-control',
            attrs: { 
                placeholder: 'Commentaire...',
                rows: 1,
                value: customerAnswerModel.CommentValue
            },
            on: {
                input: (event: string) : void => {
                    customerAnswerModel.CommentValue = event;
                }
            }
        });
    }
}