import { CommonHelper } from "@/models/common/common-helper";
import { LeadNeedSurveyAnswerBaseModel } from "../LeadNeedSurveyAnswerBaseModel";
import { CreateElement, VNode } from "vue/types/umd";

export class LeadNeedSurveyTwoTextFieldAnswerModel extends LeadNeedSurveyAnswerBaseModel {
    public FirstFieldValue: string;
    public SecondFieldValue: string;

    public constructor(firstFieldValue: string, secondFieldValue: string) {
        super();
        this.FirstFieldValue = firstFieldValue;
        this.SecondFieldValue = secondFieldValue;
    }

    public create(value: string) : LeadNeedSurveyTwoTextFieldAnswerModel {
        return CommonHelper.isNotNullOrUndefined(value) ? JSON.parse(value) as LeadNeedSurveyTwoTextFieldAnswerModel : new LeadNeedSurveyTwoTextFieldAnswerModel(null, null);
    }

    public render(h: CreateElement, customerAnswerModel: LeadNeedSurveyTwoTextFieldAnswerModel) : VNode {
        return h('v-row', [
            h('v-col', { attrs: { cols: '12', sm: '6'} }, [
                h('v-text-field', {
                    class: 'form-control',
                    attrs: {
                        type: 'text',
                        value: customerAnswerModel.FirstFieldValue
                    },
                    on: {
                        input: (event: string) : void => {
                            customerAnswerModel.FirstFieldValue = event;
                        }
                    }
                })
            ]),
            h('v-col', {attrs: { cols: '12', sm: '6'} }, [
                h('v-text-field', {
                    class: 'form-control',
                    attrs: { 
                        type: 'text',
                        value: customerAnswerModel.SecondFieldValue
                    },
                    on: {
                        input: (event: string) : void => {
                            customerAnswerModel.SecondFieldValue = event;
                        }
                    }
                })
            ])
        ]);
    }
}