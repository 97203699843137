export class SendLeadNeedSurveyCommandBodyModel {
    public QuestionID: number;
    public Answer: string;
    public Category: number;

    public constructor(questionID: number, answer: string, category: number) {
        this.QuestionID = questionID;
        this.Answer = answer;
        this.Category = category;
    }
}