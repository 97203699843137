import { SendLeadNeedSurveyCommandBodyModel } from "./send-lead-need-survey-command-body-model";

export class SendLeadNeedSurveyModel {
    public Answers: SendLeadNeedSurveyCommandBodyModel[];
    public CustomerID: number;

    public constructor(answers: SendLeadNeedSurveyCommandBodyModel[], customerID: number) {
        this.Answers = answers;
        this.CustomerID = customerID;
    }
}