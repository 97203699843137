export default {
  "Echec de tentative de connexion.": "Echec de tentative de connexion.",
  "Unauthorized": "Votre session a expiré. Merci de vous reconnecter.",
  "Internal Server Error": "Une erreur est survenue lors de l'action.",
  "Enregistrer": "Enregistrer",
  "Annuler": "Annuler",
  "Modifier": "Modifier",
  "Envoyer": "Envoyer",
  "Confirmer": "Confirmer",
  "De": "De",
  "A": "à",
  "HomePage": "Page d'accueil",
  "Prenom": "Prénom",
  "Nom": "Nom",
  "Mail": "Email",
  "Home": "Accueil",
  "Previous" : "Précédent",
  "Next" : "Suivant",
  "Send" : "Envoyer",
  "bannieretexte": (context) => {
    const url = context.named('url');
    return `Préparons dès maintenant le renouvellement de votre contrat ! Cliquez sur <a href="${url}">ce lien</a> pour nous en dire plus.`;
  },
  AppMenu: {
    "MonEspace": "Mon espace",
  },
  Locales: {
    en: "Anglais",
    fr: "Français",
  },
  FormValidators: {
    required: "Ce champ est obligatoire",
    imageSize: "La taille de l'image ne doit pas excéder 10Mo",
    imageType: "Le format de l'image n'est pas valide",
    emailFormat: "Le format de l'email n'est pas valide",
  },
  ErrorPage: {
    defaultMessage: "Une erreur est survenue."
  },
  UserSettingsOptIn: {
    title: "Messages",
    yoopalaSection: "Messages de Yoopala",
    accountActivityEmail: "Emails concernant les activités du compte (indispensables à la gestion de votre compte, vous ne pouvez pas les désactiver)",
    yoopalaInformationEmail: "Emails d'informations et promotions",
    yoopalaInformationSMS: "SMS d'informations et promotions",
    yoopalaInformationPush: "Push d'informations et promotions",
    newAdvertsEmail: "Emails concernant les nouvelles annonces proches de chez vous",
    partnersSection: "Messages des partenaires de Yoopala",
    partnersInformationEmail: "Emails d'informations et promotions",
    partnersInformationSMS: "SMS d'informations et promotions",
    action: "Enregistrer",
  },
  UserSettingsDeleteAccount: {
    title: "Suppression du compte",
    description: "La suppression de votre compte est définitive.",
    action: "Supprimer mon compte",
    deleteConfirm: "Êtes-vous certain de vouloir supprimer votre compte Yoopala ? Cette action est irréversible.",
    actionConfirmation: "Votre compte a bien été supprimé.",
  },
  UserSettingsLanguageSelector: {
    title: "Langue",
  },
  ResetPasswordModal: {
    title: "Mot de passe oublié",
    description: "Un e-mail sera envoyé à l'adresse indiquée.",
    emailLabel: "Email",
    confirmation: "Si cette adresse email est connue de Yoopala, nous vous enverrons un email pour réinitialiser votre mot de passe.",
  },
  ResetPassword: {
    title: "Réinitialisez votre mot de passe",
    actionConfirmation: "Votre mot de passe a bien été modifié.",
  },
  UserSettingsPassword: {
    title: "Mot de passe",
    currentPasswordLabel: "Mot de passe actuel",
    newPasswordLabel: "Nouveau mot de passe",
    newPasswordConfirmationLabel: "Confirmez le nouveau mot de passe",
    passwordMustBeStrongRule: "Votre nouveau mot de passe doit contenir un minimum de 8 caractères avec au moins une majuscule, une minuscule et un chiffre",
    passwordMustNotContainsEmailPrefixRule: "Votre nouveau mot de passe ne doit pas contenir votre email",
    passwordAndConfirmationMustEqual: "Votre nouveau mot de passe et sa confirmation doivent être identiques",
    action: "Changer mon mot de passe",
    checkNewPasswordConfirmation: "Les mots de passe ne sont pas identiques",
    actionConfirmation: "Votre mot de passe a bien été modifié. Merci de vous reconnecter.",
  },
  UserSettingsPage: {
    title: "Gérer mes paramètres",
  },
  BabySitterProfilePage: {
    title: "Mon profil",
  },
  UnauthorizedPage: {
    message: "Vous n'êtes pas autorisé à accéder à cette page.",
  },
  UserHeaderMenu: {
    "SignOut": "Déconnexion",
    "UserSettings": "Paramètres",
  },
  LoginPage: {
    "title": "Connexion",
    "email-label": "Email",
    "password-label": "Mot de passe",
    "forgot-password": "Mot de passe oublié ?",
    "login-action": "Se connecter",
    "signup": "Pas de compte ? Inscrivez-vous",
  },
  SponsorshipPage: {
    "title": "Parrainer mes amis",
    "hint": 'Vous pouvez consulter les conditions de notre offre de parrainage <a href="https://www.yoopala.com/ressources/parrainage-yoopala/" target="_blank">en cliquant ici</a>.',
  },
  Resume: {
    "advert": "Présentation",
    "advert-placeholder": "Ajoutez un titre et une description à votre présentation afin de pouvoir la publier.",
    "description-label": "Description",
    "description-placeholder": "Ajoutez une description à votre présentation, par exemple : Je m'appelle Julie, j'ai 23 ans et j'habite à Paris dans le 10ème arrondissement. Je suis actuellement en Licence Administration Economique et Sociale à la faculté de Nanterre. J'ai un diplôme d'animation (BAFA) et j'aime beaucoup les enfants. J'ai l'habitude de garder des enfants car je suis issue d'une famille de 4 enfants...",
    "title-label": "Titre",
    "title-placeholder": "Indiquez un titre de présentation, par exemple : Julie, super babysitter de 23 ans diplômée du BAFA",
    titleMessage: (ctx) => {
      const wordCount = ctx.named('wordCount');
      switch (true) {
        case (wordCount === 0):
          return '<span style="color:red">Votre titre doit contenir entre 3 et 12 mots.</span>';
        case (wordCount > 0 && wordCount < 3 || wordCount > 12):
          return `<span style="color:red"><strong>${wordCount} mot${wordCount > 1 ? 's' : ''}.</strong> Votre titre doit contenir entre 3 et 12 mots.</span>`;
        case (wordCount >= 3 && wordCount <= 12):
          return `<span style="color:green"><strong>${wordCount} mots.</strong> Bravo, votre titre est au top!</span>`;
        default:
          return "";
      }
    },
    descriptionMessage: (ctx) => {
      const wordCount = ctx.named('wordCount');
      switch (true) {
        case (wordCount === 0):
          return '<span style="color:red">Petit rappel, votre description doit contenir au minimum 30 mots.</span>';
        case (wordCount > 0 && wordCount < 30):
          return `<span style="color:red"><strong>${wordCount} mot${wordCount > 1 ? 's' : ''}.</strong> Petit rappel, votre description doit contenir au minimum 30 mots.</span>`;
        case (wordCount >= 30 && wordCount < 80):
          return `<span style="color:blue"><strong>${wordCount} mots.</strong> Bravo, votre description contient 30 mots ou plus, allez jusqu'à 80 pour obtenir une description au top.</span>`;
        case (wordCount >= 80):
          return `<span style="color:green"><strong>${wordCount} mots.</strong> Félicitations, votre description est au top !</span>`;
        default:
          return "";
      }
    },
  },
  EducationSection: {
    "level": "Niveau",
    "sector": "Thématique",
    "specific-diplomas": "Diplômes spécifiques",
    "section-title": "Études & Diplômes",
    "emptyMessage": "Renseignez votre niveau d'étude ainsi que vos diplômes obtenus.",
    "na": "Non renseigné",
    "no-CriteriaEducationLevel": "Sans aucun diplôme",
  },
  SearchFrequency: {
    title: "Fréquence de recherche"
  },
  SpokenLanguage: {
    title: "Langues couramment parlées",
    hint: "Indiquez les langues pour lesquelles vous avez un niveau avancé."
  },
  Transport: {
    title: "Transport",
    "driving-license": "Permis voiture (B)",
    "convey-authorization": "Véhicule permettant de transporter des enfants"
  },
  ProfessionalSituation: {
    title: "Situation actuelle",
  },
  ProfileProgressionPanel: {
    completed: "Félicitations, votre profil est complété à 100% !",
    uncompleted: ctx => `Votre profil est complété à ${ctx.named("progression")}%. Complétez l'ensemble des informations pour augmenter vos chances d'être contacté(e).`,
  },
  Availability: {
    title: "Disponibilités",
    summary: (context) => `Disponible de ${context.named('startTime')} à ${context.named('endTime')}`,
    "add-slot": "Ajouter un créneau",
    emptyMessage: "Configurez vos horaires et vos jours de disponibilité sur la semaine.",
  },
  CareServices: {
    title: "Service(s) proposé(s)",
    "standard-care-label": "Garde d’enfant(s) à domicile",
    "housework-service-label": "Ménage à domicile",
    "hasHomeworkHelpOption-label": "Avec aide aux devoirs (niveau primaire)",
    "hasIroningOption-label": "Avec repassage",
  },
  Experience: {
    title: "Expérience | Expériences",
    summary: (context) => {
      const expType = context.named('expType');
      const childAge = context.named('childAge');
      const duration = context.named('duration');
      const guardType = context.named('guardType');
      const numChildren = context.named('numChildren');
      let result = "";
      if (expType)
        result += `Expérience <strong>${expType}</strong>`
      if (guardType)
        result += `, <strong>${guardType}</strong>,`
      if (numChildren)
        result += ` concernant ${numChildren} `
        if (childAge)
        result += ` <strong>${childAge}</strong>`
      if (duration)
        result += ` pendant une période de <strong>${duration}</strong>`;
      return result;
    },
    "experience-type": "Type d'expérience",
    "nombre-enfant": "Nombre d'enfants gardés",
    "nombre-enfant-message":"Indiquez le nombre d'enfants",
    "child-age": "Âge du plus jeune",
    "child-age-message": "Si vous gardez plusieurs enfants en même temps, indiquez l'âge du plus jeune",
    "duration-message": "Période pendant laquelle vous avez gardé l'enfant",
    duration: "Durée de garde",
    guardType: "Type de garde",
    "guardType-message": "Indiquez le type de votre garde",
    description: "Description",
    "cannot-edit-experience": "Modification impossible, contactez Yoopala",
    "confirm-delete": "Êtes-vous sûr de vouloir supprimer cette expérience ?",
    emptyMessage: "Ajoutez vos différentes expériences de garde.",
  },
  ConfirmModal: {
    title: "Confirmation",
    message: "Êtes-vous sûr de vouloir effectuer cette action ?",
  },
  UserProfileAvatar: {
    photoPickerTitle: "Modifier ma photo",
    photoPickerDescription: ctx => `
    <p>
      Votre photo de profil est très importante car c'est la première
      chose que les familles regardent. Celle-ci doit donner confiance.
    </p>
    <p>Votre photo de profil est visible publiquement.</p>
    <p>
      Pour plus d'informations consultez nos conseils et des exemples
      concernant les photos sur
      <a href="${ctx.named('photoGuideUrl')}" target="_blank" class="orange--text"
        >notre guide.</a
      >
    </p>`,
    fileUploadLabel: "Choisir une image",
    fileUploadRequirements: "Formats acceptés : png, jpeg, jpg, gif - Taille max : 10Mo",
    deleteConfirm: "Êtes-vous sûr de vouloir supprimer votre photo de profil ?",
  },
  AdvertPublication: {
    "AdvertPublished": ctx => `Profil public : Vous êtes visible auprès des recruteurs Yoopala et sur Yoopala.com, <a href="${ctx.named('visibiliteCoordonneesGuideUrl')}" target="_blank" onclick="stopPropagation(event)">en savoir plus.</a>`,
    "AdvertUnpublished": "Profil privé : Vous êtes visible uniquement auprès des recruteurs Yoopala.",
  },
  SocialNetworks: {
    "title": "Réseaux sociaux",
    "hint": 'Partagez votre lien sur Facebook, Twitter et où vous le souhaitez avec le bouton "Copier le lien".',
    "fb-btn-label": "Partager sur Facebook",
    "twitter-btn-label": "Partager sur Twitter",
    "copy-link-btn-label": "Copier le lien"
  },
  SponsorshipTracking: {
    "title": "Suivi des parrainages"
  },
  Sponsor: {
    "title": "Envoyer un email",
    "send-action": "Envoyer une invitation",
    "action-confirmation": "Votre demande de parrainage a été envoyée"
  },
  Header: {
    "dashboard": "Tableau de bord",
    "searchJobs": "Voir les jobs",
    "searchAdverts": "Voir les annonces"
  },
  NotFoundPage: {
    message: "La page que vous souhaitez consulter est introuvable.",
  },
  EmployeeContractsPage: {
    description: "Retrouvez ici l'ensemble de vos contrats de travail, vos attestations Pôle-emploi ainsi que vos certificats de travail.",
    "empty-contract": "Aucun contrat à afficher",
    "Contract": "Contrat",
    "From": "Du",
    "Since": "Depuis le",
    "To": "au",
    "Status": "Statut",
    "your-documents": "Vos documents disponibles",
    "empty-document": "Aucun document disponible",
    "attestation-pole-emploi": "Attestation Pôle-emploi",
    "certificat-travail": "Certificat de travail",
    "your-contract": "Votre contrat",
    "details": "Détails",
    "signature-date": "Date de signature",
    "monthlyHoursVolume": "Nombre d'heures mensuelles",
    "sign-contract-action": "Signer le contrat",
    "show-all": "Afficher tout",
  },
  CustomerContractsPage: {
    description: "Retrouvez ici l'ensemble de vos contrats.",
    "empty-contract": "Aucun contrat à afficher",
    "Contract": "Contrat",
    "From": "Du",
    "Since": "Depuis le",
    "To": "au",
    "Status": "Statut",
    "SimpleGuard": "Garde simple",
    "SharedGuard": "Garde partagée",
    "details": "Détails",
    "monthlyHoursVolume": "Nombre d'heures mensuelles",
    "sign-contract-action": "Signer le contrat",
    "show-all": "Afficher tout",
    "fullTimeTypology": "Temps plein",
    "oneShotTypology": "Ponctuel",
    "standartTypology": "Standard",
    "child": "enfant",
    "children": "enfants",
    "Draft": "Brouillon",
    "ReadyToSend": "En attente d'envoi",
    "WaitingForSignature": "En attente de signature",
    "WaitingSignature": "En attente de signature",
    "Signed": "Signé",
    "Ongoing": "En cours",
    "Finished": "Terminé",
    "Declined": "Refusé",
    "InProgress": "En cours",
    "Canceled": "Annulé",
    "Refused": "Refusé",
    "Expired": "Expiré",
    "Error": "Erreur",
    "HouseworkContract": "Aide-ménagère",
    "BabysitterContract": "Garde d'enfant",
    "Cleaning" : "Aide-ménagère seule",
    "CleaningAndIroning" : "Aide-ménagère + Repassage"
  },
  CustomerContactsPage: {
    "chargee-de-clientele": "Chargé(e) de Clientèle",
    "chargee-de-clientele-description": "A contacter si votre demande concerne votre contrat (modification de votre besoin, prolongation, arrêt des gardes…).",
    "gestionnaire-clientele": "Gestionnaire Clientèle",
    "gestionnaire-clientele-description": "A contacter si votre demande concerne vos plannings, vos factures, vos remboursements CAF, vos règlements.",
    "reclamation": "Réclamation",
    "reclamation-description": "Coordonnées à n’utiliser que si notre équipe n’a pas trouvé de solutions à vos problématiques.",
    "nous-ecrire": "Nous écrire",
  },
  CustomerRenewalPage: {
    "rentree-scolaire": "Rentrée scolaire",
    "renouveler-contrat-septembre": "Je souhaite renouveler mon contrat pour septembre",
    "needContinueWithBS": "Je souhaite poursuivre avec ma/mes baby-sitter(s) :",
    "needBSForSummer": "J'ai aussi besoin de ma/mes baby-sitter(s) sur l'été :",
    "commentNeedNewBS": "J'ai besoin d'une nouvelle baby-sitter :",
    "needComment": "Je connais mon besoin :",
    "checkYes": "Oui",
    "checkNo": "Non",
    "demandeenvoyee": "Le formulaire a bien été envoyé.",
    "demandeerreur": "Une erreur s'est produite lors de l'envoi du formulaire.",
    "commentplaceholder": "Précisions",
    "infosprisesencompte": "Vos informations sont déjà prises en compte pour la rentrée scolaire. Vous pouvez contacter Yoopala si vous souhaitez apporter des modifications."
  },
  CustomerLeadNeedSurvey: {
    "sendSucceeded" : "Vos réponses ont bien été enregistrés !",
    "sendFailed": "Oops ! Une erreur est survenue, veuillez réessayer plus tard."
  },
  EmployeeRenewalPage: {
    "plusdispogardeenfants": "Je ne suis plus du tout disponible pour de la garde d'enfants",
    "experiencemaldéroulee": "Mon expérience Yoopala s'est mal déroulée",
    "pastravailleragence": "Je ne souhaite plus travailler en agence",
    "souhaitepousuivrefamille": "Vous souhaitez poursuivre avec la/les famille(s) :",
    "dispoannescolaire": "Vous connaissez vos disponibilités pour l’année scolaire",
    "datedispo": "A quelle date aurez-vous connaissance de vos disponibilités ?",
    "envoidemande": "Envoyer ma demande",
    "infosprisesencompte": "Vos informations sont déjà prises en compte pour la rentrée scolaire. Vous pouvez contacter Yoopala si vous souhaitez apporter des modifications.",
    "demandeenvoyee": "Merci, votre demande a bien été envoyée !",
    "poursuivregardescolaire": (context) => {
      const currentYear = context.named('currentYear');
      return `Souhaitez-vous poursuivre les gardes à la rentrée scolaire ${currentYear}
      avec
      Yoopala
      ?`
    },
      "rentreescolairetitre": (context) => {
        const currentYear = context.named('currentYear');
        const nextYear = context.named('nextYear');
        return `Rentrée scolaire ${currentYear} - ${nextYear}`
    },
    "quelleraison": "Pour quelle raison ?",
    "famille": (context) => { 
      const familyName = context.named('familyName');
      return `Famille ${familyName}`;
  }
  },
  AppFooter: {
    "description": "Créée en 2009, Yoopala est une société de services à la personne Française agréée par l'État et spécialisée dans la garde d’enfant(s) à domicile. Avec plus de 7 000 salariés et près de 700 000 heures de garde réalisées chaque année, Yoopala fait partie des acteurs majeurs de son domaine d’activité.",
    "Société": "Société",
    "Assistance en ligne": "Assistance en ligne",
    "Nous contacter": "Nous contacter",
    "Carrières": "Carrières",
    "Mentions légales": "Mentions légales et conditions générales ",
    "Politique de protection des données": "Politique de protection des données",
    "Informations": "Informations",
    "legal": "© YOOPALA - Couverture Nationale dont Paris et IDF, Marseille, Lyon, Toulouse, Bordeaux, Nice, Lille, Nantes. Siège social : 19 boulevard Malesherbes, 75008 PARIS. S.A. au capital de 1.041.225 € - R.C.S. PARIS 488 791 203 - Tel : 09 88 77 66 80 - Fax : 01 77 75 45 44 - Web : yoopala.com. Yoopala Services dispose d’un agrément délivré par la DIRECCTE UT 75 (35, rue de la gare-CS 60003 - 75144 Paris Cedex 19) sous le N°SAP 488 791 203.",
  },
  Catalogs: {
    BabysitterAgencySections: {
      jobs: {
        title: "Voir les jobs",
        description: "Voir les offres d'emploi disponibles dans votre secteur et postulez",
      },
      contract: {
        title: "Mes contrats de travail",
        description: "Retrouvez et consultez tous vos documents contractuels",
      },
      prestation: {
        title: "Mes interventions",
        description: "Retrouvez et gérez vos interventions",
      },
      payslip: {
        title: "Mes fiches de paie",
        description: "Retrouvez et consultez tous vos bulletins de salaire",
      },
      toread: {
        title: "A consulter",
        description: "Retrouvez et consultez tous vos documents légaux",
      },
      contacts: {
        title: "Mes contacts",
        description: "Accédez aux coordonnées de vos conseillers Yoopala",
        info: "Retrouvez ici l'ensemble de vos contacts Yoopala",
        arh: {
          title : "Assistant Ressources Humaines",
          desc: "A contacter seulement si votre demande concerne votre dossier du personnel (arrêt maladie, saisie des heures, paie, contrat, votre référent, etc.)",
          mail: "administratif@yoopala.com",
        },
        refSecteur: {
          title : "Référent secteur",
          desc: "A contacter seulement si votre demande concerne des questions relatives à la prestation de garde d'enfants : sécurité, éveil, hygiène, bien-être des enfants...",
          mail: "administratif@yoopala.com",
        },
        reclamation: {
          title : "Réclamation",
          desc: "Cette adresse mail n'est à utiliser que si nos équipes n'ont pas trouvé de solution à vos problématiques",
          mail: "assistance@yoopala.com",
        },
        numUrgence: {
          title : "Numéro d'urgence",
          desc: "Contactez uniquement en cas d'accident ou de problèmes graves durant la garde avec l'enfant",
          tel: "06.08.99.41.56",
        },
        adresseARH: {
          title : "Adresse Administration des Ressources Humaines",
          service: "YOOPALA Services",
          adresse: "2740 Chemin Saint Bernard",
          codePostal: "06220 VALLAURIS",
        },
        ecrire: {
          title : "Nous écrire",
          service: "YOOPALA SERVICES",
          cs: "CS 10281",
          codePostal: "06227 VALLAURIS CEDEX",
        }
      },
      sponsorship: {
        title: "Parrainage",
        description: "Parrainez vos amis et contacts",
      },
      profile: {
        title: "Mon profil",
        description: "Gérez vos informations personnelles et nécessaires à la qualification de votre profil",
      },
    },
    CustomerAgencySections: {
      contract: {
        title: "Mes contrats",
        description: "Retrouvez l'ensemble de vos contrats Yoopala",
      },
      prestation: {
        title: "Mes interventions",
        description: "Gérez les heures de garde réalisées par votre intervenant(e)",
      },
      invoices: {
        title: "Mes factures",
        description: "Payez vos factures en ligne et consultez votre historique"
      },
      payments: {
        title: "Mes règlements",
        description: "Consultez l'historique des règlements reçus par Yoopala"
      },
      "fiscal-certificates": {
        title: "Attestations fiscales",
        description: "Consultez vos attestations fiscales afin de faire valoir vos droits"
      },
      contacts: {
        title: "Mes contacts",
        description: "Retrouvez les coordonnées de votre conseiller Yoopala",
      },
      sponsorship: {
        title: "Parrainage",
        description: "Parrainez vos amis et contacts",
      },
      profiles: {
        title: "Voir les profils",
        description: "Consultez les profils disponibles dans votre secteur"
      },
      estimations: {
        title: "Mes estimations",
        description: "Consultez les estimations que vous avez demandées"
      }
    }
  },
  Enums: {
    CriteriaEducationLevelEnum: {
      1258: "BEPC",
      49: "CAP/BEP",
      1259: "BAC",
      50: "BAC +1",
      51: "BAC +2",
      52: "BAC +3",
      53: "BAC +4",
      54: "BAC +5",
      1260: "BAC +8 / Doctorat",
      1262: "Sans aucun diplôme",
    },
    CriteriaEducationThemeEnum: {
      55: "Agriculture",
      56: "Architecture",
      57: "Art",
      58: "Artisanat",
      59: "Audiovisuel",
      60: "Banques et assurances",
      61: "Bâtiment",
      62: "Coiffure",
      63: "Commerce",
      64: "Communication",
      65: "Comptabilité",
      66: "Droit",
      67: "Economie",
      68: "Environnement",
      69: "Immobilier",
      70: "Langue",
      71: "Marketing",
      72: "Mathématiques",
      73: "Publicité",
      74: "Qualité",
      75: "Ressources Humaines",
      76: "Secrétariat",
      77: "Sport",
      78: "Tourisme",
      79: "Vente",
      80: "Autre",
    },
    CriteriaDiplomasEnum: {
      1241: "Agrément d'assistant(e) maternel(le)",
      1242: "Formation PSC1 ou STT",
      1247: "Diplôme dans le secteur de l’enfance",
      1248: "Diplôme dans le secteur du médico-social",
      1249: "Diplôme dans le secteur de l’animation",
      1250: "Autre",
    },
    DayOfWeekEnum: {
      0: "Dimanche",
      1: "Lundi",
      2: "Mardi",
      3: "Mercredi",
      4: "Jeudi",
      5: "Vendredi",
      6: "Samedi"
    },
    BabySitterExperienceCategorieEnum: {
      0: "au sein de ma famille",
      1: "chez un particulier",
      2: "en crèche",
      3: "en centre de loisirs",
      4: "en colonie de vacances",
      5: "évènements (mariages, anniversaires...)",
      6: "en agence",
      7: "en école"
    },
    ChildrenAgeCategoryEnum: {
      0: "Agé de moins de 6 mois",
      1: "Agé de 6 à 11 mois",
      3: "Agé de 1 à 2 ans",
      6: "Agé de 3 à 5 ans",
      7: "Agé de 6 ans et plus"
    },
    BabySitterExperienceDurationEnum: {
      3: "De 0 à 3 mois",
      6: "De 4 à 5 mois",
      12: "De 6 à 11 mois",
      13: "Plus d'un an"
    },
    CriteriaSpokenLanguageEnum: {
      106: "Français",
      1: "Anglais",
      4: "Allemand",
      2: "Espagnol",
      3: "Italien",
      111: "Portugais",
      112: "Russe",
      113: "Arabe",
      114: "Mandarin",
      1251: "Néerlandais",
      116: "Autres langues"
    },
    CriteriaChildCareSkillsEnum: {
      5: "Aide aux devoirs",
      120: "Aide aux devoirs",
      107: "Donner le bain",
      1252: "Changer un enfant",
      109: "Préparer le repas",
      108: "Donner à manger",
      1253: "Donner le biberon",
      110: "Activités et jeux d'éveil"
    },
    BabySitterAvatarStateEnum: {
      0: "En attente de validation",
      1: "Validé",
      2: "Rejeté",
    },
    SponsorshipStateEnum: {
      0: "Email envoyé",
      1: "Filleul inscrit(e)"
    },
    BabySitterProfessionalSituationEnum: {
      0: "Indéfini",
      1: "Étudiant(e)",
      2: "En recherche d’emploi",
      4: "En activité professionnelle",
      5: "Retraité(e)",
      6: "En formation professionelle",
      99: "Autre"
    },
    BabySitterExperienceGuardTypeEnum: {
      0: "Avant/après l’école",
      1: "En journée",
      2: "En soirée",
      3: "Le week-end",
    },
    BabySitterSearchFrequencyEnum: {
      0: "Non renseigné",
      1: "Régulièrement (une ou plusieurs fois par semaine)",
      2: "Ponctuellement (de temps en temps, à l'occasion)",
      4: "A temps plein (c'est votre activité principale, votre métier)"
    },
    ChildrenNumCategoryEnum:{
      1: "1 enfant",
      2: "2 enfants",
      3: "3 enfants",
      4: "4 enfants ou +"
    },
    DaysOfWeekEnum: {
      0: 'Lundi',
      1: 'Mardi',
      2: 'Mercredi',
      3: 'Jeudi',
      4: 'Vendredi',
      5: 'Samedi',
      6: 'Dimanche',
    }
  },
  Components: {
    AddressSearch: {
      charRemains: 'caractères restants pour lancer la recherche.'
    }
  }
};
